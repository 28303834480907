




























import { Component, Vue, Prop } from "vue-property-decorator";
import Dialog from "@/components/elements/Dialog.vue";
import Modal from "@/components/elements/Modal.vue";
import axios from "axios";
import { APIURL } from "../../store/globals";

/**
 * Renders the cancel abonnoment dialog
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: { Modal, Dialog }
})
export default class CancelAboDialog extends Vue {
  /**
   * Returns whether the dialog is open or not
   * 
   * @var isOpen
   * @type boolean
   */
  @Prop() private isOpen!: boolean;

  /**
   * Returns the current status of the dialog
   * 
   * 0 = Initial
   * 1 = Success
   * 2 = Error
   * 3 = Loading
   * 
   * @var status
   * @type number
   */
  private status = 0;

  /**
   * Returns the close text
   * 
   * @var closeText
   * @type string
   */
  private closeText = '';

  /**
   * Returns whether the app needs to be reloaded or not
   * 
   * @var needsReload
   * @type boolean
   */
  public needsReload = false;

  /**
   * Resets the dialog
   * 
   * @returns void
   */
  reset() {
      this.status = 0;
      this.closeText = 'Abbrechen';
      this.needsReload = false;
  }

  /**
   * Gets called when the element is created
   */
  created() {
      this.reset();
  }

  /**
   * Closes the dialog
   * 
   * @returns void
   */
  private close() {
    this.$emit("close");
  }

  /**
   * Sets the status to finished
   * 
   * @returns void
   */
  finished() {
      this.status = 1;
      this.closeText = 'OK';
  }

  /**
   * Sets the status to error
   * 
   * @returns void
   */
  error() {
      this.status = 2;
      this.closeText = 'OK';
  }

  /**
   * Cancels the abonnement
   * 
   * @returns void
   */
  async confirm() {
      this.status = 3;
      const res = await axios
        .post(APIURL + "/cancelAbonnement", {
            sid: this.$store.state.session
        }),
        data = res.data;
      if(data) {
        this.needsReload = true;
        this.finished();
      } else {
        this.error();
      }
      
  }
}
