
















































import { Component, Vue } from "vue-property-decorator";
import PageFrame from "@/components/elements/PageFrame.vue";
import axios from "axios";
import CancelAboDialog from "../dialogs/CancelAboDialog.vue";
import { APIURL } from "../../store/globals";

/**
 * Renders the profile page
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: {
    PageFrame,
    CancelAboDialog
  }
})
export default class Profile extends Vue {

  /**
   * Returns the email value
   * 
   * @var email
   * @type string
   */
  email = "";

  /**
   * Returns the company name value
   * 
   * @var companyName
   * @type string
   */
  companyName = "";

  /**
   * Returns the ustid value
   * 
   * @var ustId
   * @type string
   */
  ustId = "";

  /**
   * Returns the contact value
   * 
   * @var contact
   * @type string
   */
  contact = "";

  /**
   * Returns the phone value
   * 
   * @var phone
   * @type string
   */
  phone = "";

  /**
   * Returns the adress/street value
   * 
   * @var adress
   * @type string
   */
  adress = "";

  /**
   * Returns the zipcode value
   * 
   * @var zipCode
   * @type string
   */
  zipCode = "";

  /**
   * Returns the city value
   * 
   * @var city
   * @type string
   */
  city = "";

  /**
   * Returns the country value
   * 
   * @var country
   * @type string
   */
  country = "";

  /**
   * Returns the iban value
   * 
   * @var iban
   * @type string
   */
  iban = "";

  /**
   * Returns the url to the change profile url
   * 
   * This url will login you to the pdfmailer cloud in the company data section
   * 
   * @var changeProfileUrl
   * @type string
   */
  changeProfileUrl = "";

  /**
   * Returns the visibility state of the cancel abo dialog
   * 
   * @var isCancelAboOpen
   * @type boolean
   */
  isCancelAboOpen = false;

  /**
   * Returns the text of the cancel abo button
   * 
   * @var cancelAboText
   * @type string
   */
  cancelAboText = "";

  /**
   * Returns the status of the abonnoment
   * 
   * @var isCanceled
   * @type boolean
   */
  isCanceled = false;

  /**
   * Sets the loading status
   * 
   * @returns void
   */
  loading() {
    this.email 
        = this.companyName 
        = this.ustId 
        = this.contact 
        = this.phone 
        = this.adress 
        = this.zipCode 
        = this.city 
        = this.country 
        = this.cancelAboText
        = 'Wird geladen...';
  }

  /**
   * Gets called on creation
   * 
   * @returns void
   */
  async created() {
    this.loading();
    const res = await axios
      .post(APIURL + "/getAccountInfo", {
        sid: this.$store.state.session
      }),
      data = res.data,
      url = await axios
      .post(APIURL + "/changeCompanyDataRedirect", {
        sid: this.$store.state.session
      });
      
      this.email = data.email;
      this.companyName = data.company;
      this.ustId = data.ustid;
      this.contact = data.contact;
      this.phone = data.tel;
      this.adress = data.street;
      this.zipCode = data.zip;
      this.city = data.city;
      this.country = data.country;

      this.changeProfileUrl = url.data;

      this.isCanceled = data.xrenew_canceled;
      if(data.xrenew_canceled) {
        this.cancelAboText = 'Konto löschen (in Bearbeitung)';
      } else {
        this.cancelAboText = 'Konto löschen';
      }
  }

  /**
   * Gets called when the cancel abo dialog was canceled
   * 
   * @returns void
   */
  cancelCancelAbo() {
    this.isCancelAboOpen = false;
    const dialog = (this.$refs.cancelDialog as CancelAboDialog);
    if(dialog.needsReload) {
      this.$router.go(0);
    }
  }

  /**
   * Opens the cancel abo dialog
   * 
   * @returns void
   */
  cancelAbo() {
    this.isCancelAboOpen = true;
    const dialog = (this.$refs.cancelDialog as CancelAboDialog);
    dialog.reset();
    if(this.isCanceled) {
      dialog.finished();
    }
  }
}
